<template>
  <div class="relative pb-30 info-index">
    <div
      class="fixed inset-0 z-0 w-full h-full mb-0 bg-opacity-75 rounded-tr-full shadow-2xl top-24 bg-gray-50 bg-gradient-to-tr from-brand-lightblue via-brand-lightblue-75 to-brand-primary lg:hidden"
      v-if="!isCheckedIn"></div>
    <div
      class="relative flex flex-col self-center justify-start w-full h-full max-w-screen-sm min-h-screen px-4 mx-auto space-y-4">
      <h1 class="text-3xl font-bold text-center">
        {{ $t("navigation.info") }}
      </h1>

      <div class="relative z-10 flex flex-col mt-6 space-y-6">
        <div class="flex flex-col space-y-4">
          <div class="grid justify-between w-full grid-cols-1 gap-4 2xs:grid-cols-2 sm:gap-6">
            <t-button :to="{ name: 'info-chems' }" variant="bigiconwhite"
              class="relative flex flex-col items-center justify-center w-full gap-4 p-4 rounded-md shadow-lg">
              <div
                class="relative flex items-center justify-center w-16 h-16 p-4 text-yellow-900 bg-yellow-300 rounded-full ring-4 ring-yellow-300 ring-opacity-25 sm:h-20 sm:w-20">
                <icon-chem-info class="w-12 h-12 overflow-auto fill-current" />
              </div>
              <span class="w-full text-base font-bold text-center truncate text-brand-dark sm:text-lg">{{
              $t("navigation.chems") }}</span>
            </t-button>

            <t-button :to="{ name: 'info-drugcombos' }" variant="bigiconwhite"
              class="relative flex flex-col items-center justify-center w-full gap-4 p-4 rounded-md shadow-lg">
              <div
                class="relative flex items-center justify-center w-16 h-16 p-4 text-yellow-900 bg-yellow-300 rounded-full ring-4 ring-yellow-300 ring-opacity-25 sm:h-20 sm:w-20">
                <icon-drugcombo class="w-12 h-12 overflow-auto fill-current" />
              </div>
              <span class="w-full text-base font-bold text-center truncate text-brand-dark sm:text-lg">{{
              $t("navigation.drugcombos")
              }}</span>
            </t-button>

            <t-button :to="{ name: 'info-assistance' }" variant="bigiconwhite"
              class="relative flex flex-col items-center justify-center w-full gap-4 p-4 rounded-md shadow-lg">
              <div
                class="relative flex items-center justify-center w-16 h-16 p-4 text-red-900 bg-red-300 rounded-full ring-4 ring-red-300 ring-opacity-25 sm:h-20 sm:w-20">
                <icon-assistance class="w-12 h-12 overflow-auto fill-current" />
              </div>
              <span class="w-full text-base font-bold text-center truncate text-brand-dark sm:text-lg">{{
              $t("navigation.assistance")
              }}</span>
            </t-button>

            <t-button :to="{ name: 'info-emergency' }" variant="bigiconwhite"
              class="relative flex flex-col items-center justify-center w-full gap-4 p-4 rounded-md shadow-lg">
              <div
                class="relative flex items-center justify-center w-16 h-16 p-4 text-red-900 bg-red-300 rounded-full ring-4 ring-red-300 ring-opacity-25 sm:h-20 sm:w-20">
                <icon-emergency-help class="w-12 h-12 overflow-auto fill-current" />
              </div>
              <span class="w-full text-base font-bold text-center truncate text-brand-dark sm:text-lg">{{
              $t("navigation.emergency")
              }}</span>
            </t-button>

            <t-button :to="{ name: 'article-overview' }" variant="bigiconwhite"
              class="relative flex flex-col items-center justify-center w-full gap-4 p-4 rounded-md shadow-lg">
              <div
                class="relative flex items-center justify-center w-16 h-16 p-4 text-blue-900 bg-blue-300 rounded-full ring-4 ring-blue-300 ring-opacity-25 sm:h-20 sm:w-20">
                <icon-articles-book-lines class="w-12 h-12 overflow-auto fill-current" />
              </div>
              <span class="w-full text-base font-bold text-center truncate text-brand-dark sm:text-lg">{{
              $t("navigation.articles")
              }}</span>
            </t-button>

            <t-button :to="{ name: 'article-chemsex' }" variant="bigiconwhite"
              class="relative flex flex-col items-center justify-center w-full gap-4 p-4 rounded-md shadow-lg">
              <div
                class="relative flex items-center justify-center w-16 h-16 p-4 text-green-900 bg-green-300 rounded-full ring-4 ring-green-300 ring-opacity-25 sm:h-20 sm:w-20">
                <icon-people class="w-12 h-12 overflow-auto fill-current" />
              </div>
              <span class="w-full text-base font-bold text-center truncate text-brand-dark sm:text-lg">{{
              $t("navigation.chemsex_stories")
              }}</span>
            </t-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconDrugcombo from "@/assets/icons/drug-pills.svg";
import IconChemInfo from "@/assets/icons/drugsinfo.svg";
import IconEmergencyHelp from "@/assets/icons/emergency-help.svg";
import IconAssistance from "@/assets/icons/assistance.svg";
import IconArticlesBookLines from "@/assets/icons/articles-book-lines.svg";
import IconPeople from "@/assets/icons/people.svg";
import { mapGetters } from "vuex";
import { infoItems } from "../../utils/constants";

export default {
  components: {
    IconDrugcombo,
    IconEmergencyHelp,
    IconAssistance,
    IconChemInfo,
    IconArticlesBookLines,
    IconPeople,
  },
  name: "info-index",
  data() {
    return {
      infoItems,
    };
  },
  computed: {
    ...mapGetters(["isCheckedIn"]),
  },
};
</script>
